export const BASEURL = {
    url: "https://haiertools.jochaho.global/api/v1/" // test
    // url: "https://service.haiermall.pk/api/v1/" // prod
  }

  export const RECORDBASEURL = {
    url: "http://maidianbackendtest.liehao.cn/api/web/index.php/"
  }

  export const ROUTEURL = {
    url: "http://119.8.190.50:8091"
  }
