import axios from 'axios'
import { Aes } from './aes'

import md5 from 'md5'
import { RECORDBASEURL } from './constApp'
import log from './log'

const aes = new Aes()

let isLocal = false

const request = axios.create({
  baseURL: isLocal ? `http://localhost:8000/api/v1/` : `${process.env.VUE_APP_HAIERTOOLS_API}api/v1/`,
  timeout: 600000
})

request.interceptors.request.use(
  (config) => {
    const token = localStorage.authToken
    const nonce = setNonce(32)
    const timestamp = new Date().getTime()
    const key = 'rh5ffurhv28m2q14'

    config.headers['Content-type'] = 'application/json'
    config.headers.Accept = 'application/json'
    config.headers.Nonce = nonce
    config.headers.Timestamp = timestamp
    if (token) {
      //  signature with token
      const param = `Authorization=Bearer ${token}&Nonce=${nonce}&Timestamp=${timestamp}&Key=${key}`
      config.headers.Sign = md5(param).toUpperCase()
      config.headers.Authorization = `Bearer ${token}`
    } else {
      // signature without token
      const param = `Nonce=${nonce}&Timestamp=${timestamp}&Key=${key}`
      config.headers.Sign = md5(param).toUpperCase()
    }
    // console.log('config.headers: ', config.headers);
    return config
  },

  (error) => {
    return Promise.error(error)
  }
)

request.interceptors.response.use(
  (response) => {
    let deResponse
    // console.log("headers[content-disposition]--", response);
    // console.log('deResponse: ', deResponse);
    // console.log("response--", response);
    // INFO:: Donot delete this code if anything breaks.
    if (
      (response.headers['content-disposition'] && response.headers['content-disposition'].indexOf('.xls') != -1) ||
      (response.headers['cache-content-disposition'] && response.headers['cache-content-disposition'].indexOf('.xls') != -1)
    ) {
      /** excel file */
      //   console.log('response: ', new Blob([response]))
      deResponse = new Blob([response.data], {
        type: response.headers['content-type']
      })
    } else if (response.data.size > 0 && response.data.type.indexOf('vnd.ms-excel') != -1) {
      /**
       * 兼容一些没有请求头"content-disposition"的浏览器
       * Compatible with some browsers that do not have a "content-disposition" request headers
       */
      deResponse = response.data
    } else if (response.data && response.data.length > 1) {
      /** normal interface data */
      try {
        deResponse = JSON.parse(aes.doDecrypt(response.data))
      } catch (err) {
        deResponse = aes.doDecrypt(response.data)
      }
    } else {
      deResponse = '' || []
    }

    log('<-', { ...response.config, data: deResponse })

    // eslint-disable-next-line no-prototype-builtins
    if (response && ((Object.prototype.hasOwnProperty.call(response, 'success') && response.success) || !Object.prototype.hasOwnProperty.call(response, 'success'))) {
      return {
        data: deResponse,
        headers: response.headers,
        statusObj: response
      }
    }
    return Promise.reject(deResponse)
  },

  (error) => {
    // if(error && (typeof(error.response.data) == 'string')) {
    //     return JSON.parse(aes.doDecrypt(error.response.data));
    // } else
    if (error.response.status) {
      console.log('error response: ', error.response)
      switch (error.response.status) {
        case 401:
          localStorage.removeItem('phpauthToken')
          setTimeout(() => {
            window.location.href = '/login'
          }, 1000)
          break
        case 403:
          localStorage.removeItem('phpauthToken')
          break
        case 422:
          var result = JSON.parse(aes.doDecrypt(error.response.data)).message[0]
          console.log('Unprocessable: ', result)
          // this.$toast.error(result)
          break
        case 404:
          window.location.href = '/404'
          break
        case 500:
          var result = JSON.parse(aes.doDecrypt(error.response.data))
          console.log(result)
          break
        default:
          console.log(error.response)
          return error.response
      }
    }
    // if (error.response.status) {
    //       console.log('error: ', error)
    //     //   const data = JSON.parse(aes.doDecrypt(error.response.data));
    //     console.log('error status code: ', error.response.status);
    //   switch (error.response.status) {
    //     // 401:

    //     case 422:
    //       log("<-", {...response.config, data: error.response.data.error[0]})
    //       console.log('Error 422: ', error.response.data.error)
    //       break;
    //     case 401:
    //       localStorage.removeItem("mst");
    //       if (error.response.data.detail != "Invalid credentials") {

    //         // elementUi.Message({
    //         //   message: "Please log in",
    //         //   type: "warning"
    //         // });
    //       }
    //        else if (error.response.status === 401) {
    //         console.log('Error: 401')
    //         // Message.closeAll();
    //         // Message.error("Your account is blocked, please contact admin");
    //       }
    //       router.push({
    //         path: "/login"
    //         // query: {
    //         //   redirect: router.currentRoute.fullPath
    //         // }
    //       });
    //       break;
    //     //    // 403 token
    //     case 403:
    //       localStorage.removeItem("mst");
    //       // elementUi.Message({
    //       //   message: "Login expired. Please log in again.",
    //       //   type: "error"
    //       // });
    //       setTimeout(() => {
    //         router.replace({
    //           path: "/login"
    //           // query: {
    //           //   redirect: router.currentRoute.fullPath
    //           // }
    //         });
    //       }, 500);
    //       break;

    //     case 404:
    //       // elementUi.Message({
    //       //   message: "network request did not exist！",
    //       //   type: "error"
    //       // });
    //       break;
    //     default:
    //       if (error.response.config.url.slice(-5) == "login") {

    //         // elementUi.Message({
    //         //   message: error.response.data.title,
    //         //   type: "error"
    //         // });
    //       } else {

    //         // elementUi.Message({
    //         //   message: error.response.data.title,
    //         //   type: "error"
    //         // });
    //       }
    //   }
    //   return Promise.reject(error);
    // }
  }
)

function setNonce(len) {
  len = len || 32
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678' /** **默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  var maxPos = $chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

function Api() {
  this.get = function (url, params) {
    log('->', { method: 'get', url, params })
    return request.get(url, { params })
  }

  this.getFile = function (url, data) {
    log('->', { method: 'getFile', url, data })
    return request.get(url, { ...data, responseType: 'blob' })
  }

  this.post = function (url, data) {
    log('->', { method: 'post', url, data })
    if (typeof data === 'string') {
      return request.post(url, aes.doEncrypt(data))
    }
    return request.post(url, aes.doEncrypt(JSON.stringify(data)))
  }

  this.put = function (url, data) {
    log('->', { method: 'put', url, data })
    if (typeof data === 'string') {
      return request.put(url, aes.doEncrypt(data))
      // return request.put(url,data)
    }
    return request.put(url, aes.doEncrypt(JSON.stringify(data)))
    // return request.put(url,JSON.stringify(data))
  }

  this.delete = function (url, config) {
    log('->', { method: 'delete', url })
    if (config && config.data && typeof config.data === 'string') {
      config.data = aes.doEncrypt(config.data)
    } else if (config && config.data) {
      config.data = aes.doEncrypt(JSON.stringify(config.data))
    }
    return request.delete(url, config)
  }

  this.patch = function (url, data) {
    log('->', { method: 'patch', url, data })
    return request.patch(url, aes.doEncrypt(JSON.stringify(data)))
  }

  this.upload = function (url, formData, config = {}) {
    log('->', { method: 'upload', url, data: formData })
    return request.post(url, formData, {
      ...config,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.mst,
        ...config.headers
      }
    })
  }

  this.maidian = function (url, params) {
    log('->', { method: 'maidian', url, params })
    return axios
      .get(url, {
        baseURL: RECORDBASEURL.url,
        params: params
      })
      .then(({ data }) => {
        log('<-', { method: 'maidian', url, data })
        return data
      })
  }
}

const php = new Api()

export default php
export { request }
